@import 'variables';

@mixin mq($type) {
  @if $type == 'mobile-only' {
    @media screen and (max-width: #{$mobile - 0.02}) {
      @content;
    }
  }

  @if $type == 'mobile' {
    @media screen and (min-width: $mobile) {
      @content;
    }
  }

  @if $type == 'tablet-before' {
    @media screen and (max-width: #{$tablet - 0.02}) {
      @content;
    }
  }

  @if $type == 'mobile-tablet' {
    @media screen and (min-width: $mobile) and (max-width: #{$tablet - 0.02}) {
      @content;
    }
  }

  @if $type == 'tablet-only' {
    @media screen and (min-width: $tablet) and (max-width: #{$desktop - 0.02}) {
      @content;
    }
  }

  @if $type == 'tablet' {
    @media screen and (min-width: $tablet) {
      @content;
    }
  }

  @if $type == 'desktop' {
    @media screen and (min-width: $desktop) {
      @content;
    }
  }

  @if $type == 'not-desktop' {
    @media screen and (max-width: #{$desktop - 0.02}) {
      @content;
    }
  }

  @if $type == 'retina' {
    @media (min-device-pixel-ratio: 2),
      (-webkit-min-device-pixel-ratio: 2),
      (min-resolution: 192dpi),
      (min-resolution: 2dppx) {
      @content;
    }
  }
}
