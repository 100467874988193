@use '../../../../styles/mixins';

.adToggleBtn {
  padding: 10px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 38.25px;
  border-radius: 10.3108px;
  border-radius: 0.5em;
  background-color: #a099cc;
  color: #fff;
  border: none;

  cursor: pointer;
  z-index: 2100;
  @include mixins.mq(tablet) {
    width: 100px;
    height: 35.25px;
  }
  @include mixins.mq(desktop) {
    width: 140px;
    height: 40px;
  }
}

.hidden {
  display: none;
}
