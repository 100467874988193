#widget-cites-div-especialitats {
  margin-top: 20px;
  margin-left: 10px;
  margin-bottom: 20px !important;
  font-family: 'Open Sans', sans-serif !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 1.5 !important;
  color: #017683 !important;
}

#widget-cites-div-especialitats select,
#widget-cites-div-professionals select {
  height: 30px;
  font-family: 'Open Sans', sans-serif !important;
  font-weight: 400 !important;
  font-size: 15px !important;
  line-height: 1.5 !important;
  color: #017683 !important;
}
#widget-cites-div-especialitats select:focus,
#widget-cites-div-professionals select:focus {
  color: #000 !important;
}

#widget-cites-div-professionals {
  margin-bottom: 20px !important;
  margin-left: 10px;
  font-family: 'Open Sans', sans-serif !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 1.5 !important;
  color: #017683 !important;
}

#eclinic-citas-widget-body td {
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 14px;
}

#eclinic-citas-widget-head th {
  background-color: #017683;
}

#eclinic-citas-widget-body,
#eclinic-citas-widget-head {
  border-collapse: collapse;
}

#eclinic-citas-widget-body td {
  border: 1px solid #017683;
}

#eclinic-citas-widget-head th {
  font-family: 'Open Sans', sans-serif !important;
  font-weight: 200;
  font-size: 14px;
  line-height: 1.5;
  border: 1px solid white;
}

/* #eclinic-citas-widget-bodycontainer {
  border: 1px solid transparent !important;
} */

.EclinicCitasWidget_widget__sc-YU {
  border: 1px solid transparent !important;
  padding-left: 10px;
  padding-right: 10px;
}
#eclinic-cita-recuerda {
  width: 100% !important;
  background-color: #017683 !important;
}
