@use '../../../styles/mixins';

.settingsWindow {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90vw;
  padding: 25px 15px;
  background: var(--main-color);
  border: 2px solid var(--accent-color);
  box-shadow: var(--hover-box-shadow);
  z-index: 2200;

  @include mixins.mq(tablet) {
    padding: 40px 20px;
    width: 490px;
  }

  @include mixins.mq(desktop) {
    width: 500px;
  }

  &__container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    cursor: pointer;
  }

  &__title {
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 14px;
    @include mixins.mq(tablet) {
      font-size: 15px;
    }
  }

  &__text {
    padding-right: 5px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-size: 13px;
    line-height: 1.3;
    list-style-type: disc;
    padding-left: 20px;
    max-height: 68px;
    overflow-y: auto;
    cursor: pointer;
    @include mixins.mq(tablet) {
      max-height: 93px;
      font-size: 14px;
    }
  }

  &__part {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__btnWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
}
