@use '../../../styles/mixins';

.topPanel {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  width: 100%;
  height: 30px;
  background-color: var(--background-color);

  &__part {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  &__text {
    margin-left: 10px;
    font-weight: 200;
    font-size: 14px;
    color: azure;
    @include mixins.mq(tablet) {
      font-size: 15px;
    }
    @include mixins.mq(desktop) {
      font-size: 18px;
    }
  }
}
