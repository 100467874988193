@use '../../../styles/mixins';
.select {
  display: block;
  font-size: 14px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  color: #444;
  line-height: 1.3;
  padding: 0.6em 1.4em 0.5em 0.8em;
  width: 60px;
  box-sizing: border-box;
  margin: 0;
  border: 1px solid #aaa;
  border-radius: 0.5em;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069a18%2018%200%2000-13-5H18c-5%200-9%203-11%207a18%2018%200%2000-5%2013c0%205%202%2010%205%2013l128%20128c4%204%2010%205%2013%205s9-1%2013-5L287%2095c3-3%205-8%205-13z%22%2F%3E%3C%2Fsvg%3E'),
    linear-gradient(to bottom, #ffffff 0%, #e5e5e5 100%);
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 0.65em auto, 100%;
  @include mixins.mq(tablet) {
    font-size: 14px;
    width: 60px;
  }
  @include mixins.mq(desktop) {
    font-size: 16px;
    width: 70px;
  }
}

.select::-ms-expand {
  display: none;
}

.select:hover {
  border-color: #888;
}

.select:focus {
  border-color: #aaa;
  box-shadow: 0 0 1px 1px rgba(0, 133, 149, 0.7);
  box-shadow: 0 0 0 3px -moz-mac-focusring;
  color: #222;
  outline: none;
}

.option {
  font-weight: normal;
}

.label {
  font-size: 0;
}
