@use '../../styles/mixins';
.footer {
  width: 100%;
  background: var(--background-color);

  &__content {
    margin-top: 30px;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    @include mixins.mq(tablet) {
      flex-direction: row;
      margin-top: 30px;
      margin-bottom: 30px;
      justify-content: space-around;
      gap: 140px;
    }
    @include mixins.mq(desktop) {
      flex-direction: row;
      margin-top: 40px;
      margin-bottom: 40px;
      justify-content: space-between;
    }
  }

  &__partLeft {
    display: flex;
    flex-direction: column;
    width: 60%;
    @include mixins.mq(tablet) {
      width: 35%;
    }
    @include mixins.mq(desktop) {
      width: 50%;
    }
  }

  &__partRight {
    display: flex;
    flex-direction: column;
    width: 40%;
    @include mixins.mq(tablet) {
      width: 35%;
    }
    @include mixins.mq(desktop) {
      width: 50%;
    }
  }

  &__mainPartRight {
    display: flex;
    flex-direction: column;
    width: 40%;
    @include mixins.mq(tablet) {
      width: 35%;
    }
    @include mixins.mq(desktop) {
      width: 50%;
    }
  }

  &__partS {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
  }

  &__partTitle {
    margin-bottom: 15px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 500;
    font-size: 19px;
    line-height: 1.2;
    color: var(--btn-text-color);
    @include mixins.mq(tablet) {
      font-size: 21px;
      line-height: 1.2;
    }
    @include mixins.mq(desktop) {
      font-size: 24px;
      line-height: 1.2;
    }
  }

  &__link {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
    width: 285px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.5;
    color: var(--btn-text-color);
    @include mixins.mq(tablet) {
      width: 263px;
      font-size: 15px;
    }
    @include mixins.mq(desktop) {
      width: 320px;
      font-size: 16px;
    }
  }

  &__linkS {
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.5;
    color: var(--btn-text-color);
    @include mixins.mq(tablet) {
      font-size: 15px;
    }
    @include mixins.mq(desktop) {
      font-size: 16px;
    }
  }

  &__iconContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 22px;
    height: auto;
  }

  &__text {
    display: inline-block;
    margin-left: 10px;
    font-size: 13px;
    @include mixins.mq(tablet) {
      font-size: 16px;
    }
  }

  &__textP {
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.5;
    color: var(--btn-text-color);
    @include mixins.mq(tablet) {
      font-size: 15px;
    }
    @include mixins.mq(desktop) {
      font-size: 16px;
    }
  }

  &__link:hover,
  &__link:focus {
    color: var(--background-btn-second-color);
  }

  &__linkS:hover,
  &__linkS:focus {
    color: var(--background-btn-second-color);
  }

  &__bottomPart {
    padding: 15px 15px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    border-top: 1px solid var(--background-btn-second-color);
    @include mixins.mq(tablet) {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 10px 0px;
    }
    @include mixins.mq(desktop) {
      padding: 15px 0px;
    }
  }

  &__linkPart {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    @include mixins.mq(tablet) {
      flex-direction: row;
      align-items: center;
      gap: 10px;
    }
  }

  &__textPolicy {
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-size: 13px;
    line-height: 1.3;
    color: var(--background-btn-second-color);
    @include mixins.mq(tablet) {
      font-size: 14px;
    }
    @include mixins.mq(desktop) {
      font-size: 14px;
    }
  }

  &__linkPolicy {
    text-align: center;
  }

  &__textPolicyMain {
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-size: 13px;
    line-height: 1.3;
    color: var(--background-btn-second-color);
    @include mixins.mq(tablet) {
      font-size: 14px;
    }
    @include mixins.mq(desktop) {
      font-size: 14px;
    }
  }

  &__textPolicy:hover {
    color: var(--btn-text-color);
  }
}
