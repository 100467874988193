@use '../../../styles/mixins';

.switchBtn {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;

  &__container {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 35px;
    height: 21px;
    background-color: red;
    padding: 1px;
    border-radius: 34px;
  }

  &__toggle {
    width: 19px;
    height: 19px;
    border-radius: 50%;
    background-color: var(--main-color);
  }

  &__text {
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.3;
    color: red;
    @include mixins.mq(tablet) {
      font-size: 15px;
    }
  }
}
