@use '../../../styles/mixins';

.window {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: calc(100vh / 2 - 200px);
  left: calc(100vw / 2 - 90vw / 2);
  width: 90vw;
  padding: 10px;
  background: var(--main-color);
  border: 2px solid var(--accent-color);
  box-shadow: var(--hover-box-shadow);
  z-index: 2200;
  @include mixins.mq(tablet) {
    top: calc(100vh / 2 - 250px);
    left: calc(100vw / 2 - 245px);
    width: 490px;
  }
  @include mixins.mq(desktop) {
    top: calc(100vh / 2 - 250px);
    left: calc(100vw / 2 - 250px);
    width: 500px;
  }

  &__border {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border: 2px solid var(--accent-color);
  }

  &__warning {
    margin-top: 50px;
    margin-bottom: 10px;
    padding: 0px 15px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    @include mixins.mq(tablet) {
      gap: 30px;
    }
  }

  &__text {
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.3;
    @include mixins.mq(tablet) {
      font-size: 14px;
    }
    @include mixins.mq(desktop) {
      font-size: 15px;
    }
  }

  &__accentText {
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 1.3;
    @include mixins.mq(tablet) {
      font-size: 14px;
    }
    @include mixins.mq(desktop) {
      font-size: 15px;
    }
  }

  &__textWrapper {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  &__btnWrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  &__linkWrapper {
    display: flex;
    flex-direction: row;
    gap: 5px;
  }

  &__link {
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.3;
    color: #0000ee;
    text-decoration: underline;
    @include mixins.mq(tablet) {
      font-size: 14px;
    }
    @include mixins.mq(desktop) {
      font-size: 15px;
    }
  }

  &__wrapper {
    flex-wrap: wrap;
    text-align: center;
    @include mixins.mq(tablet) {
      text-align: left;
    }
  }

  &__widget {
    margin-top: 50px;
    margin-bottom: 10px;
    position: relative;
  }

  &__closeButton {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: 10px;
    right: 10px;
    width: 30px;
    height: 30px;
    padding: 2px;
    background-color: transparent;
    border: 2px solid var(--accent-color);
    border-radius: 50%;
  }
}
