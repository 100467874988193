@use '../../../styles/mixins';
.policyWindow {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0px;
  width: 100%;
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  background-color: var(--background-color);
  z-index: 2200;
  @include mixins.mq(tablet) {
    height: 120px;
    padding: 10px 20px;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
  }
  @include mixins.mq(desktop) {
    height: 70px;
    padding: 10px 40px;
  }

  &__btnPart {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }

  &__textPartWrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    @include mixins.mq(tablet) {
      width: 70%;
    }
  }
}

.policyWindow__textPart {
  flex-wrap: wrap;
  gap: 8px;
  color: var(--btn-text-color);
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.3;
  text-align: center;
  @include mixins.mq(tablet) {
    text-align: left;
    font-size: 15px;
  }

  p,
  a,
  button {
    margin: 0;
    display: inline;
  }

  .cookies__text {
    display: inline;
  }

  .cookies__textPolicy:hover {
    color: var(--background-btn-second-color);
  }

  button {
    background-color: transparent;
    border: none;
    padding: 0px 5px;
    cursor: pointer;
    color: var(--btn-text-color);
    text-decoration: underline;
  }

  button:hover {
    color: var(--background-btn-second-color);
  }
}
