@use '../../styles/mixins';
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: var(--main-color);
  z-index: 1000;

  &__content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    @include mixins.mq(tablet) {
      padding: 10px 0;
    }
    @include mixins.mq(desktop) {
      padding: 10px 10px;
    }
  }

  &__part {
    display: flex;
    flex-direction: column;
    align-items: center;
    @include mixins.mq(tablet) {
      position: absolute;
      margin-left: 180px;
      display: flex;
      flex-direction: column;
      gap: 15px;
      width: 200px;
    }
    @include mixins.mq(desktop) {
      margin-left: 200px;
      display: flex;
      flex-direction: row;
      gap: 40px;
      width: 440px;
    }
  }

  &__partClinic {
    display: grid;
    gap: 7px;
  }

  &__title {
    margin-bottom: 5px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 500;
    font-size: 21px;
    line-height: 18px;
    color: var(--text-color);
    text-align: center;
    @include mixins.mq(tablet) {
      margin-bottom: 0px;
      font-size: 21px;
      line-height: 18px;
      text-align: left;
    }
    @include mixins.mq(desktop) {
      font-size: 24px;
      line-height: 22px;
    }
  }

  &__text {
    margin-bottom: 10px;
    font-weight: 200;
    font-size: 15px;
    @include mixins.mq(tablet) {
      margin-bottom: 0px;
      font-size: 15px;
    }
    @include mixins.mq(desktop) {
      font-size: 18px;
    }
  }

  &__linkWrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
    @include mixins.mq(tablet) {
      gap: 10px;
    }
    @include mixins.mq(desktop) {
      gap: 20px;
    }
  }
}
