@use '../../../styles/mixins';
.bottomPanel {
  width: 100%;
  background-color: var(--accent-color);

  &__content {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 10px;
    width: 100%;
  }

  &__menu {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 45px;
    border: 2px solid #aaa;
    border-radius: 0.5em;
    background-color: transparent;
  }

  &__menuIcon {
    width: 30px;
    height: 40px;
  }

  &__menuNavigation {
    left: 0;
    margin-top: 54px;
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  &__menuContainer {
    padding: 0 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.85);
  }
}

.btnsWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}
