@use '../../../styles/mixins';
.adWindow {
  position: fixed;
  bottom: -560px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 560px;
  padding: 10px;
  background-image: url('../../../images/advertising/advertising04.webp');
  background-size: cover;
  background-position: bottom;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: bottom 2s ease;
  z-index: 2000;
  @include mixins.mq(tablet) {
    bottom: -380px;
    padding: 10px;
    width: 768px;
    background-image: url('../../../images/advertising/advertising01.webp');
    background-position: center;
  }
  @include mixins.mq(desktop) {
    bottom: -435px;
    padding: 20px;
    width: 1280px;
  }

  &__text {
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.5;
    color: var(--text-color);
    @include mixins.mq(tablet) {
      font-size: 14px;
    }
    @include mixins.mq(desktop) {
      font-size: 18px;
    }
  }

  &__textTitle {
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 1.5;
    text-align: center;
    color: var(--text-color);
    @include mixins.mq(tablet) {
      text-align: left;
      font-size: 17px;
    }
    @include mixins.mq(desktop) {
      font-size: 18px;
    }
  }

  &__title {
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 20px;
    line-height: 1.2;
    text-align: center;
    color: var(--text-color);
    @include mixins.mq(tablet) {
      line-height: 1.5;
      font-size: 25px;
      text-align: left;
    }
    @include mixins.mq(desktop) {
      line-height: 1.2;
      font-size: 32px;
    }
  }

  &__showBox {
    position: absolute;
    bottom: 20px;
    left: 55.4%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: row;
    gap: 20px;
    @include mixins.mq(tablet) {
      left: 57.5%;
    }
    @include mixins.mq(desktop) {
      left: 55.4%;
    }
  }

  &__showBoxPart {
    padding: 5px 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100px;
    border-radius: 10px;
    background-image: url('../../../images/advertising/advertising02.jpg');
    background-size: cover;
    background-position: center;
  }

  &__listContent {
    padding: 5px 0px;
    padding-left: 10px;
    background-color: #ffffff56;
    width: 100%;
    border: 1px solid #a099cc25;
    @include mixins.mq(tablet) {
      width: 280px;
    }
    @include mixins.mq(desktop) {
      width: 360px;
    }
  }

  &__promo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 110px;
    height: 110px;
    background-image: url('../../../images/advertising/advertising03.png');
    background-size: cover;
    background-position: center;
    @include mixins.mq(tablet) {
      width: 130px;
      height: 130px;
    }
  }

  &__promoWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 20px;
    @include mixins.mq(tablet) {
      justify-content: initial;
      gap: 30px;
      height: 130px;
    }
  }

  &__rotatedText {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transform: rotate(-30deg);
    width: 65px;
    height: 50px;
    border: 1px solid white;
    @include mixins.mq(tablet) {
      width: 75px;
      height: 60px;
    }
  }

  &__bottomPart {
    width: 100%;
    height: 250px;
    background-image: url('../../../images/advertising/advertising05.webp');
    background-size: cover;
    background-position: center;
  }
}

.adWindow.visible {
  bottom: 0px;
}

.adWindow.visibleWindow {
  display: none;
  @include mixins.mq(tablet) {
    display: block;
    bottom: 120px;
  }
  @include mixins.mq(desktop) {
    bottom: 70px;
  }
}

.closeBtn {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: transparent;
  border: 1px solid #6c6969f4;
  border-radius: 50%;
  cursor: pointer;
  @include mixins.mq(tablet) {
    width: 40px;
    height: 40px;
  }
  @include mixins.mq(desktop) {
    width: 44px;
    height: 44px;
  }
}

.adContent {
  padding: 10px 0px;
  padding-top: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  @include mixins.mq(tablet) {
    padding: 0px 0px;
    align-items: flex-start;
    gap: 5px;
  }
  @include mixins.mq(desktop) {
    margin-left: 70px;
    gap: 10px;
  }
}

.adToggleBtn {
  position: fixed;
  bottom: 30%;
  transform: translateY(-50%);
  right: 20px;
  padding: 10px 20px;
  width: 80px;
  border-radius: 10.3108px;
  background-color: #a099cc;
  color: #fff;
  border: none;
  cursor: pointer;
  z-index: 2100;
  @include mixins.mq(tablet) {
    bottom: 50%;
    width: 145px;
  }
  @include mixins.mq(desktop) {
    width: 180px;
  }
}

.hidden {
  display: none;
}
